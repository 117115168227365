<template>
    <div class="home">
        <top-user-bar
                :userPhoto="$store.getters.userinfo.avatar"
                :userName="$store.getters.userinfo.nickName"
                :city="$store.getters.city.name"
                :ifRightLink="true"
        ></top-user-bar>
        <!-- 搜索 -->
        <form action="/">
            <van-search
                    v-model="keywords"
                    shape="round"
                    placeholder="请输入商品名称"
                    @search="onSearch"
            />
        </form>
        <!-- 列表下拉刷新 -->
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" direction="up">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                    style="margin-bottom: 100px"
            >
                <product-card v-for="(item,index) in list" v-bind="item" :key='index'/>
            </van-list>
        </van-pull-refresh>
        <!-- 底部导航 -->
        <bottom-bar/>

    </div>
</template>

<script>
  // @ is an alias to /src
  import TopUserBar from "@/components/TopUserBar.vue";
  import BottomBar from "@/components/BottomBar.vue";
  import ProductCard from "@/components/ProductCard.vue";
  import {getGoodsList} from "@/api/goods";
  import {getWxConfig} from "@/api/wx";

  export default {
    name: "Home",
    data () {
      return {
        userInfo: {
          photoUrl: require("@/assets/defaultuserpic.png"),
          userName: "小丸子",
          city: "北京站",
        },
        keywords: '',
        loading: false,
        finished: false,
        refreshing: false,
        pageNum: 1,
        pages: 1,
        list: [],
        latitude: 0,
        longitude: 0,
      }
    },
    methods: {
      async loadWxConfig () {
        const url = window.location.href;
        const res = await getWxConfig(url);
        if (res.success == true) {
          wx.config({
            debug: false,
            appId: res.data.appId,
            timestamp: res.data.timestamp,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
            jsApiList: [
              'getLocation',
            ]
          });
        }
      },
      loadGoodsList () {
        if (this.pageNum > this.pages) {
          this.loading = false;
          return
        }
        this.loading = true;
        getGoodsList({
          special: 1,
          pageNum: this.pageNum,
          title: this.keywords,
          siteId: this.$store.getters.city.id,
          longitude: this.longitude,
          latitude: this.latitude,
        }).then(data => {
          if (data.success == true) {
            this.pageNum = data.data.current + 1;
            this.pages = data.data.pages;
            this.list.push(...this.handleData(data.data.records));
            if (this.pageNum > this.pages) {
              this.finished = true;
            }
          } else {
            this.finished = true;
          }
        }).finally(() => {
          this.loading = false
        })
      },
      handleData (data) {
        const res = [];
        data.forEach(item => {
          res.push({
            id: item.productId,
            picUrl: item.productCoverImg,
            productTitle: item.title,
            leftTip: true,
            rightTip: true,
            leftTipTxt: item.isSellOut ? '已售罄' : '抢购中',
            timer: item.endTime,
            FreeShipping: item.productType == 2,
            realPrice: parseFloat(item.price),
            oldPrice: parseFloat(item.marketPrice),
            backPrice: parseFloat(item.commission),
            sales: parseInt(item.showSales),
          })
        });
        return res
      },
      onSearch (val) {
        this.keywords = val;
        this.refreshing = true;
        this.onLoad();
      },
      onLoad () {
        if (this.refreshing) {
          this.list = [];
          this.pageNum = 1;
          this.pages = 1;
          this.refreshing = false;
        }
        this.loadGoodsList()
      },
      onRefresh () {
        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.onLoad();
      },
      getLocation() {
        var _this = this
        wx.ready(function(){
          wx.getLocation({
            type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: function (res) {
              _this.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
              _this.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
            },
            complete: () => {
              _this.loadGoodsList()
            }
          });
        });
      }
    },
    created () {
      this.loadWxConfig();
      this.getLocation();
    },
    components: {
      "top-user-bar": TopUserBar,
      'product-card': ProductCard,
      'bottom-bar': BottomBar
    },
  };
</script>
