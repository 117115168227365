import {getAreaList} from "@/api/system";

const app = {
  state: {
    buy: {
      product: 0,
      spec: 0,
    },
    city: {
      id: 0,
      name: '',
    },
    areaList: {},
    pid: 0,
  },
  mutations: {
    SET_BUY_PRODUCT: (state, buy) => {
      state.buy = {
        ...buy
      };
    },
    SET_CITY: (state, data) => {
      state.city = data;
      window.localStorage.setItem('city', JSON.stringify(data))
    },
    SET_AREA_LIST: (state, data) => {
      state.areaList = data
    },
    SET_PID: (state, data) => {
      state.pid = data
    }
  },
  actions: {
    GetAreaList(store) {
      return new Promise(async (resolve, reject) => {
        if (store.state.areaList.length > 0) {
          resolve(store.state.areaList)
        } else {
          const res = await getAreaList();
          if (res.success = true) {
            const provinces = {};
            res.data.provinces.forEach(item => {
              provinces[item.areano] = item.title
            });
            const cities = {};
            res.data.cities.forEach(item => {
              cities[item.areano] = item.title
            });
            const areas = {};
            res.data.areas.forEach(item => {
              areas[item.areano] = item.title
            });
            const areaList  = {
              province_list: provinces,
              city_list: cities,
              county_list: areas
            };
            store.commit('SET_AREA_LIST', areaList);
            resolve(areaList)
          }
        }
      })
    }
  }
};

export default app
