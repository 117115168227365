
<template>
  <div class="bottombar">
	<router-view />
      <van-tabbar route>
           <van-tabbar-item replace to="/">
                <span>爆品</span>
                <template #icon="props">
                     <img :src="props.active ? icon1.active : icon1.inactive" />
                </template>
            </van-tabbar-item>
<!--            <van-tabbar-item replace to="/periphery">-->
<!--                <span>附近</span>-->
<!--                <template #icon="props">-->
<!--                     <img :src="props.active ? icon2.active : icon2.inactive" />-->
<!--                </template>-->
<!--            </van-tabbar-item>-->
            <van-tabbar-item replace to="/expproducts">
                <span>好物</span>
                <template #icon="props">
                     <img :src="props.active ? icon3.active : icon3.inactive" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/usercenter">
                <span>我的</span>
                <template #icon="props">
                     <img :src="props.active ? icon4.active : icon4.inactive" />
                </template>
            </van-tabbar-item>

      </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'BottomBar',
  data:function() {
    return {
         icon1: {
                active: require("@/assets/home-active.svg"),
                inactive:  require("@/assets/home-inactive.svg"),
            },
        icon2: {
                active: require("@/assets/periphery-active.svg"),
                inactive:  require("@/assets/periphery-inactive.svg"),
            },
        icon3: {
                active: require("@/assets/expproducts-active.svg"),
                inactive:  require("@/assets/expproducts-inactive.svg"),
            },
        icon4: {
                active: require("@/assets/usercenter-active.svg"),
                inactive:  require("@/assets/usercenter-inactive.svg"),
            },
    }
  },
  props: {

  },
  methods:{

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bottombar{
 }
</style>
