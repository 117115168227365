<template>
    <div class="productcard">
        <div class="card-top" @click="goToDetail(id)">
            <img :src="picUrl">
            <span class="tipleft" v-if="leftTip">{{ leftTipTxt }}</span>
            <!-- <span class="tipright" v-if="rightTip && showTimer">
              {{ showTimer }}
            </span> -->
            <span class="tipright" v-if="rightTip && showTimer">
                  <van-count-down :time="showTimer" format="DD 天 HH 时 mm 分 ss 秒" @finish="finishTime"/>
                </span>
        </div>
        <div class="card-title">
            {{ productTitle }}
            <span class="free" v-if='FreeShipping'>包邮</span>
        </div>
        <div class="info">
            <div class="left">
                <span class="realprice">￥{{ realPrice }}</span>
                <span class="oldprice">门市价￥{{ oldPrice }}</span>
                <span class="backprice">返￥{{ backPrice }}</span>
            </div>
            <div class="right">销售量：{{ sales }}</div>
        </div>
    </div>
</template>

<script>
  import {addShare} from "@/api/goods";

  export default {
    name: "ProductCard",
    props: {
      id: {
        type: Number,
        default: 0
      },
      picUrl: {
        type: String,
        default: ''
      },
      leftTip: {
        type: Boolean,
        default: false
      },
      leftTipTxt: {
        type: String,
        default: '抢购中'
      },
      rightTip: {
        type: Boolean,
        default: false
      },
      timer: {
        type: Number,
        default: ''
      },
      productTitle: {
        type: String,
        default: ''
      },
      FreeShipping: {  //是否包邮 默认否
        type: Boolean,
        default: false
      },
      realPrice: {   //现价
        type: Number,
        default: 0
      },
      oldPrice: {   //原价
        type: Number,
        default: 0
      },
      backPrice: {   //返现
        type: Number,
        default: 0
      },
      sales: {   //销售量
        type: Number,
        default: 0
      },
    },
    data () {
      return {
        now: new Date(),
        end: null,
        showTimer: 0,
      }
    },
    methods: {
      goToDetail (id) {
        window.location.href = '/productpage/' + id
      },
      doTimer () {
        if (this.timer > 0) {
          this.end = this.$moment(new Date(this.timer * 1000));
          this.doCal();
          // setInterval(() => {
          //   this.doCal()
          // }, 1000);
        }
      },
      doCal () {
        this.now = this.$moment(new Date());
        const seconds = this.end.diff(this.now, 'seconds');
        this.showTimer = seconds * 1000
      },
      finishTime () {
        // TODO
        // 倒计时结束业务
      },
    },
    created () {
      this.doTimer()
    }
  };
</script>

<style lang="scss">
    .tipright .van-count-down {
        color: #fff;
        font-size: 12px;
    }
</style>

<style scoped lang="scss">
    .productcard {
        margin: 0 15px 15px 15px;
        box-shadow: 0 0 5px #cdcdcd;
        background: #fff;
        border-radius: 5px;
        overflow: hidden;
        text-align: left;

        .card-top {
            height: 130px;
            overflow: hidden;
            position: relative;

            img {
                width: 100%;
            }

            .tipleft {
                position: absolute;
                left: 0;
                top: 10px;
                background: rgb(0, 191, 55);
                border-radius: 0 5px 5px 0;
                color: #fff;
                font-size: 12px;
                padding: 3px 8px;
            }

            .tipright {
                position: absolute;
                right: 0;
                top: 10px;
                background: #D50606;
                border-radius: 5px 0 0 5px;
                color: #fff;
                font-size: 12px;
                padding: 3px 8px;
            }
        }

        .card-title {
            padding: 10px 15px;
            font-size: 15px;
            color: #333;

            .free {
                color: #fff;
                font-size: 12px;
                background: #D50606;
                padding: 2px 5px;
                border-radius: 4px;
            }
        }

        .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;
            border-top: 1px solid #ddd;

            span {
                display: inline-block;
                vertical-align: middle;
            }

            .realprice {
                font-size: 16px;
                color: #D50606;
            }

            .oldprice {
                font-size: 12px;
                color: #999;
                text-decoration: line-through;
                padding: 0 10px;
            }

            .backprice {
                font-size: 12px;
                color: #fff;
                background: rgb(240, 209, 1);
                padding: 2px 8px;
                border-radius: 4px;
            }

            .right {
                font-size: 12px;
                color: #666;
            }

            .left {
                // display: flex;
            }
        }
    }

</style>
