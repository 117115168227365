

// 微信登录
import request from "@/utils/request";

export function login (data) {
  return request({
    url: '/wx/wxLogin',
    method: 'post',
    data: {
      ...data,
    }
  })
}


// 发送验证码
export function getSms (phone) {
  return request({
    url: '/sms/s',
    method: 'post',
    data: {
      telephone: phone
    }
  })
}


// 申请达人
export function submitDR(params) {
  return request({
    url: '/user/levelUp',
    method: 'post',
    data: params
  })
}

// 获取达人信息
export function getDrInfo () {
  return request({
    url: '/user/account/userInfo',
    method: 'get'
  })
}

// 获取用户总收益
export function userAccountStat () {
  return request({
    url: '/user/account/statistics',
    method: 'get'
  })
}

// 获取直卖订单列表
export function getSelfBuyOrderList(params) {
  return request({
    url: '/user/account/statistics/selfBuy/orderList',
    method: 'get'
  })
}

// 用户提现接口
export function userWithdrawal (params) {
  return request({
    url: '/user/withdrawal/apply',
    method: 'post',
    data: params
  })
}

// 提现中列表
export function getUserWithdrawalProcessing (params) {
  return request({
    url: '/user/account/statistics/withdrawalProcessing/detail',
    method: 'get',
    params
  })
}

// 已提现列表
export function getUserWithdrawalFinish (params) {
  return request({
    url: '/user/account/statistics/withdrawalFinish/detail',
    method: 'get',
    params
  })
}

// 我的团队
export function getUserMyTeamInfo (params) {
  return request({
    url: '/user/account/statistics/myTeam/detail',
    method: 'get',
    params
  })
}

// 提现审核未通过列表
export function getUserWithdrawalFailed () {
 return request({
   url: '/user/account/statistics/withdrawalFailed/detail',
   method: 'get'
 })
}

// 商家登录接口
export function shopLogin (params) {
  return request({
    url: '/login',
    method: 'post',
    data: params
  })
}

// 商家修改密码
export function changePass (params) {
  return request({
    url: '/cp',
    method: 'post',
    data: params
  })
}

// 微信绑定
export function doBlind (params) {
  return request({
    url: '/wx/wxLogin',
    method: 'post',
    data: {
      ...params,
      type: 1,
    }
  })
}


// 用户分享二维码
export function userRq () {
  return request({
    url: '/user/qr',
    method: 'post'
  })
}

export function userQbs (data) {
  return request({
    url: '/user/qbs',
    method: 'post',
    data
  })
}

export function userQrbill () {
  return request({
    url: '/user/qrBill',
    method: 'post',
  })
}

// 刷新用户信息
export function flushUserInfo () {
  return request({
    url: '/user/flush',
    method: 'post',
  })
}

// 我的访客记录
export function getVisitorList (data) {
  return request({
    url: '/v/l',
    method: 'post',
    data
  })
}

// 获取提现列表
export function getTixian(data) {
  return request({
    url: '/user/account/statistics/myTeam/upLevelWithdrawalDetail',
    method: 'get',
    params: data,
  })
}

export function getMyTeamList () {
  return request({
    url: '',
    method: 'get',
  })
}

// 绑定上下级关系
export function userBind (data) {
  return request({
    url: '/user/blind',
    method: 'post',
    data: {
      id: data
    }
  })
}

// 扫码记录
export function visitorLog (data) {
  return request({
    url: '/v/log',
    method: 'post',
    data
  })
}

// 获取二维码
export function userUpPic () {
  return request({
    url: '/user/levelUpPic',
    method: 'post'
  })
}
