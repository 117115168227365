import store from '@/store'

export default function Initializer () {
  let userInfo = window.localStorage.getItem('userinfo');
  store.commit('SET_TOKEN', window.localStorage.getItem('token'));
  if ( userInfo !="" && userInfo !==null) {
    store.commit('SET_INFO', JSON.parse(userInfo));
  }else{
    store.commit('SET_INFO', []);
  }
  // 设置城市
  let city = JSON.parse(window.localStorage.getItem('city'));
  if (city == null || !city.name) {
    city = {
      id: 1,
      name: '青岛站'
    }
  }

  store.commit('SET_CITY', city);
  store.commit('SET_TYPE', window.localStorage.getItem('type'));
}
