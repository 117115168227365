import Vue from "vue";
import Vuex from "vuex";
import user from './modules/user'
import app from './modules/app'
import getters from "@/store/getters";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    app
  },
  getters
});
