import router from './router'
import store from './store'

router.beforeEach(async (to, from, next) => {

  if (to.query.refresh) {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('type');
    window.localStorage.removeItem('userinfo');
    window.localStorage.removeItem('pid');
    window.localStorage.removeItem('login_shop');
    window.localStorage.removeItem('code');
    window.localStorage.removeItem('prev_url');
  }

  if (to.path == '/login' || to.path == '/qr') {
    next();
    return
  }

  if (to.path == '/blind' && window.localStorage.getItem('code')) {
    next();
    return;
  }

  const pid = to.query.pid;
  if (pid) {
    window.localStorage.setItem('pid', pid);
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userinfo');
  }

  const shop = to.query.shop;
  if (shop) {
    window.localStorage.removeItem('prev_url');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('type');
    window.localStorage.removeItem('userinfo');
    window.localStorage.setItem('login_shop', true);
  }

  if (to.path == '/blind' && window.localStorage.getItem('token')) {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userinfo');
  }

  const token = window.localStorage.getItem('token');
  if (token != 'null' && token) {
    // 检查路由
    const shopUrl = ['/sjhexiao', '/changepass', '/xhjilv'];
    const isShopUrl = shopUrl.indexOf(to.path) === -1;
    const type = window.localStorage.getItem('type');
    const is_shop = type === 'shop';
    const is_user = type === 'user';
    if (is_shop && isShopUrl) {
      window.location.href = '/sjhexiao';
      return
    }
    if (is_user && !isShopUrl) {
      next({path: '/'});
      return
    }
    next();
    return;
  } else {
    if (to.query && to.query.code) {
      const url = window.localStorage.getItem('prev_url');
      if (url && url.indexOf('/blind') != -1) {
        window.localStorage.setItem('code', to.query.code);
        window.location.href = url;
        return;
      }

      // return;
      store.dispatch('Login', {
        code: to.query.code,
        pid: window.localStorage.getItem('pid'),
        type: window.localStorage.getItem('login_shop') ? 1 : 0,
      })
        .then(data => {
          if (url) {
            if (data == false) {
              window.location.href = url
            }
          } else {
            next({path: '/'});
            return
          }
          if (data.toString().length > 0) {
            next({path: data})
          }
        }).catch(() => {
            next({path: '/login'});
          return;
        })
    } else {
      if (!to.query.shop && !to.query.refresh) {
        window.localStorage.setItem('prev_url', window.location.href);
      }
      // console.log(process.env);
      const appid = process.env.VUE_APP_APPID;
      const redirect_uri = window.location.protocol + '//' + window.location.host;
      // console.log(redirect_uri);
      const url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + redirect_uri + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';
      window.location.href = url;
    }
  }

});
