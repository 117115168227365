import request from "@/utils/request";


// 获取商品列表
export function getGoodsList (params) {
  return request({
    url: '/product/list',
    method: 'get',
    params
  })
}

// 获取商品详情
export function getGoodsInfo (params) {
  return request({
    url: '/product/detail',
    method: 'get',
    params
  })
}

// 商品增加分享数
export function addShare (id) {
  return request({
    url: '/product/share',
    method: 'post',
    data: {
      productId:id
    }
  })
}

// 获取商品分享图
export function getBill (id) {
  return request({
    url: '/product/bill',
    method: 'post',
    data: {
      id
    }
  })
}


// 获取商品分享图
export function getBill2 (id) {
  return request({
    url: '/product/bill2',
    method: 'post',
    data: {
      id
    }
  })
}
