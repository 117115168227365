import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import bootstrap from "./bootstrap";
import Vant from "vant";
import "vant/lib/index.css";
import moment from "moment";
import "./permission";

Vue.prototype.$moment = moment;

Vue.use(Vant);

import Amap from "vue-amap";
Vue.use(Amap);
Amap.initAMapApiLoader({
  key: "20cbbe19885f65a24420b5971ab8f6f8",
  plugin: [
    "AMap.Geolocation",
    "AMap.Autocomplete",
    "MarkerClusterer",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.AMapManager",
    "AMap.ToolBar"
  ],
  v: "1.4.14",
  uiVersion: "1.0.11"
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  created: bootstrap,
  render: h => h(App)
}).$mount("#app");
