const getters = {
  userinfo: state => state.user.info,
  is_shop: state => state.user.type == 'shop',
  is_user: state => state.user.type == 'user',
  buy: state => state.app.buy,
  city: state => state.app.city,
  pid: state => state.app.pid
};

export default getters
