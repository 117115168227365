import {login} from "@/api/user";

const user = {
  state: {
    token: '',
    info: {},
    type: ''
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      window.localStorage.setItem('token', token)
    },
    SET_INFO: (state, data) => {
      state.info = data;
      window.localStorage.setItem('userinfo', JSON.stringify(data))
    },
    SET_TYPE: (state, data) => {
      state.type = data;
      window.localStorage.setItem('type', data);
    }
  },
  actions: {
    // 登录
    Login({commit}, data) {
      return new Promise(async (resolve, reject) => {
        const res = await login(data);
        console.log(res);
        if (res.success == true) {
          commit('SET_TOKEN', res.data.token);
          commit('SET_INFO', res.data);
          if (window.localStorage.getItem('login_shop')) {
            commit('SET_TYPE', 'shop');
            window.localStorage.removeItem('prev_url');
            window.localStorage.removeItem('login_shop');
          } else {
            commit('SET_TYPE', 'user');
          }
          if (data.pid) {
            if (res.data.redirect != 3) {
              resolve('/drsmzuce')
            } else {
              resolve('/drsmyzuce');
            }
            window.localStorage.removeItem('pid');
            resolve(true);
          }
          resolve(false)
        } else {
          window.localStorage.removeItem('type');
          window.localStorage.removeItem('login_shop');
          reject(res.msg)
        }
      })
    },
    Logout({commit}) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '');
        commit('SET_INFO', '');
        commit('SET_TYPE', '');
        resolve()
      })
    }
  }
};

export default user
