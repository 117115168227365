

// 获取 wx 配置参数
import request from "@/utils/request";

export function getWxConfig (url) {
  return request({
    url: '/wx/config',
    method: 'post',
    data: {
      url
    }
  })
}
