<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
    <van-popup v-model="showQr" class="qrModal" closeable :close-on-click-overlay="false" @close="closeQr">
      <div class="qr">
        <img src="./assets/erweima2.jpg">
      </div>
    </van-popup>
  </div>
</template>
<script>
  import { Popup } from 'vant';
  export default {
    data() {
      return {
        showQr: false
      }
    },
    methods: {
      checkSubscribe () {
        if (this.$store.getters.userinfo.subscribe == false) {
          this.showQr = true
        }
      },
      closeQr() {
        // this.$store.dispatch('Logout').then(() => {
        //   window.location.reload()
        // });
        this.showQr = false
      }
    },
    created() {
      this.checkSubscribe();
    }
  }
</script>
<style lang="scss">
*{padding:0;margin:0;}
html,body,#app {height: 100%;}
body{background:#f7f8fa;  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

#nav {
  padding: 30px;

  a,a:visited {
    font-weight: bold;
    color: #1989fa;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.van-search.van-search--show-action{padding:5px 0 5px 15px;margin:0 0 10px 0;box-shadow: 0 1px 2px #cdcdcd;
   .van-search__action{padding: 0 18px;}
}
body .van-tabbar-item--active{color:#D50606;}
.productpage{
 .van-radio__icon{font-size:12px;border:1px solid #666;border-radius: 3px;padding:6px 8px;margin-right:10px;position:relative;overflow: hidden;}
 .van-radio__icon.van-radio__icon--checked{border-color:#D50606;color:#D50606;}
 .van-radio__icon.van-radio__icon--checked::after{content:'';width: 0; height: 0; border-top: 10px solid red; border-left: 15px solid transparent;position:absolute;top:0;right:0;}
}
.van-nav-bar .van-nav-bar__left .van-icon{color:#333;}
.txt-red{color:#D50606;}
.menubar{ .van-tabbar-item__icon{margin:0 0 10px 0;}}
.details img {width: 100%}
  .qr {
    img {
      width: 100%;
      margin-top: 2rem;
    }
  }
  .qrModal {
    background-color: transparent !important;
  }
</style>
