import Vue from "vue";
import VueRouter from "vue-router";
import Vant from 'vant';
import Home from "../views/Home.vue";
import 'vant/lib/index.css';

Vue.use(VueRouter);
Vue.use(Vant);

// eslint-disable-next-line no-sparse-arrays
const routes = [
  {
    path: "/",
    name: "Home",   //本地
    component: Home
  },
  {
    path: '/qr',
    name: 'Qr',
    component: () => import('../views/Qr')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/logout')
  },
  {
    path: '/blind',
    name: 'blind',
    component: () => import('../views/blind')
  },
  {
    path: "/periphery",  //周边
    name: "periphery",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/Periphery.vue")
  },
  {
    path: "/expproducts",  //爆款
    name: "expproducts",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/Expproducts.vue")
  },
  {
    path: "/usercenter",  //个人中心首页  Completed
    name: "usercenter",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/UserCenter.vue")
  },
  {
    path: "/usercenterunpaid",  //个人中心未支付
    name: "usercenterunpaid",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/UserCenterUnpaid.vue")
  },
  {
    path: "/usercenterpaid",  //个人中心已支付
    name: "usercenterpaid",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/UserCenterPaid.vue")
  },
  {
    path: "/usercentercompleted",  //个人中心已完成
    name: "usercentercompleted",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/UserCenterCompleted.vue")
  },
  {
    path: "/productpage/:id",  //商品详情
    name: "productpage",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/ProductPage.vue")
  },
  {
    path: "/shopaddress",  //商品详情-商家地址
    name: "shopaddress",
    component: () =>
        import(/* webpackChunkName: "" */ "../views/map")
  },
  {
    path: "/citys",//选择站点
    name: "citys",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/Citys.vue")
  },
  {
    path: "/buy",  //立即购买
    name: "buy",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/Buy.vue")
  },
  {
    path: "/changepass",  //修改密码
    name: "Changepass",
    component: () => import(/* webpackChunkName: "" */ "../views/changepass.vue")
  },
  {
    path: "/Login",  //登录
    name: "Login",
    component: () =>
        import(/* webpackChunkName: "" */ "../views/login.vue")
  },
  {
    path: "/myteam",  //我的团队
    name: "Myteam",
    component: () =>
        import(/* webpackChunkName: "" */ "../views/myteam.vue")
  },
  {
    path: "/xhjilv",  //核销记录
    name: "Xhjilv",
    component: () =>
        import(/* webpackChunkName: "" */ "../views/xhjilv.vue")
  },
  {
    path: "/sjhexiao",  // 猫着拼团-商家核销平台
    name: "Sjhexiao",
    component: () =>
        import(/* webpackChunkName: "" */ "../views/sjhexiao.vue")
  },
  {
    path: "/drsmzuce",  //达人打扫-注册
    name: "Drsmzuce",
    component: () =>
        import(/* webpackChunkName: "" */ "../views/register.vue")
  },
  {
    path: "/drsmyzuce",  //达人打扫-注册
    name: "Drsmyzuce",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/drsmyzuce.vue")
  },

  {
    path: "/Wdjinku",  //我的金库
    name: "Wdjinku",
    component: () =>
        import(/* webpackChunkName: "" */ "../views/wdjinku.vue")
  },
  {
    path: "/Drxinxi",  //达人信息
    name: "Drxinxi",
    component: () =>
        import(/* webpackChunkName: "" */ "../views/drxinxi.vue")
  },
  {
    path: "/sqtixian",  //申请提现
    name: "Sqtixian",
    component: () =>
        import(/* webpackChunkName: "" */ "../views/sqtixian.vue")
  },
  {
    path: "/ytxian",  //已提现
    name: "Ytxian",
    component: () =>
        import(/* webpackChunkName: "" */ "../views/ytxian.vue")
  },
  {
    path: "/txzhong",  //提现中
    name: "Txzhong",
    component: () =>
        import(/* webpackChunkName: "" */ "../views/txzhong.vue")
  },
  {
    path: "/shwtongguo",  //审核未通过
    name: "Shwtongguo",
    component: () => import(/* webpackChunkName: "" */ "../views/shwtongguo.vue")
  },
  {
    path: "/orderinfo/:id",  //订单详情
    name: "Orderinfo",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/ddxqxuni.vue")
  },
  {
    path: "/wlxinxi",  //物流
    name: "Wlxinxi",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/wlxinxi.vue")
  },
  {
    path: "/tkshenhezhong",  //物流
    name: "tkshenhezhong",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/tkshenhezhong.vue")
  },

  {
    path: "/tkchenggong",  //物流
    name: "tkchenggong",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/tkchenggong.vue")
  },

  {
    path: "/tuikuan",  //物流
    name: "tuikuan",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/tuikuan.vue")
  },
  {
    path: "/tytuihuo",  //物流
    name: "tytuihuo",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/tytuihuo.vue")
  },
  {
    path: "/wxtuihuo",  //物流
    name: "wxtuihuo",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/wxtuihuo.vue")
  },
  {
    path: "/xytuihuo",  //物流
    name: "xytuihuo",
    component: () =>
      import(/* webpackChunkName: "" */ "../views/xytuihuo.vue")
  },
  {
    path: "/addressLat",  //物流
    name: "addressLat",
    component: () => import(/* webpackChunkName: "" */ "../views/map.vue")
  },
  {
    path: "/xx",  //物流
    name: "xytuihuo",
    component: () =>
        import(/* webpackChunkName: "" */ "../views/drsmyzuce.vue")
  },
  {
    path: "/pay-protocol",  //支付协议
    name: "payprotocol",
    component: () =>import(/* webpackChunkName: "" */ "../views/payProtocol.vue")
  },
  {
    path: "/commission-protocol",  //佣金协议
    name: "commissionprotocol",
    component: () =>import(/* webpackChunkName: "" */ "../views/commissonProtocal")
  },
  {
    path: '/coffers',  // 金库
    name: 'coffers',
    component: () => import("../views/coffers.vue")
  },
  {
    path: '/visitor',  // 访客记录
    name: 'visitor',
    component: () => import("../views/visitor.vue")
  },
  {
    path: '/team',  // 访客记录
    name: 'team',
    component: () => import("../views/team.vue")
  },
  {
    path: '/tixian',  // 访客记录
    name: 'tixian',
    component: () => import("../views/tixian.vue")
  },
  {
    path: '/hai/:id',
    name: 'Hai',
    component: () => import('../views/Hai.vue')
  },
  {
    path: '/tuan',
    name: 'tuan',
    component: () => import('../views/Tuan.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue')
  },
  {
    path: '/register-success',
    name: 'register-success',
    component: () => import('../views/register-success.vue')
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
