import axios from 'axios'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000 // 请求超时时间
});


request.interceptors.request.use(config => {
  const token = window.localStorage.getItem("token");
  // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI0NjUyIn0.yGNQOuG4swaHb8Rh4ZYlVAsaA1n8QNAix7XfooUuhfQ";
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config
});

const errorHandler = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      window.localStorage.removeItem('token');
      window.location.reload()
    }
  }
  return Promise.reject(error)
};


// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler);


export default request
