<template>
    <!-- 带用户头像的顶部条 默认显示右侧地区选择-->
    <div class="topuserbar">
        <div class="uesrbox" @click="goToCenter">
            <div class="userphoto"><img :src="userPhoto" alt=""></div>
            <div class="name"> {{ userName }}</div>
        </div>
        <div v-if="ifRightLink" class="rightlink" @click="toCity">
            {{ city }}
        </div>
    </div>
</template>

<script>
  export default {
    name: 'TopUserBar',
    data: function () {
      return {}
    },
    props: {
      userPhoto: {
        type: String,
        default: require('@/assets/defaultuserpic.png')
      },
      ifRightLink: {
        type: Boolean,
        default: true
      },
      userName: {
        type: String,
        default: ''
      },
      city: {
        type: String,
        default: ''
      }

    },
    methods: {
      toCity() {
        if (this.$route.path == '/citys') {
          this.$router.back()
        } else {
          this.$router.push('/citys')
        }
      },
      goToCenter() {
        this.$router.push('/usercenter')
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .topuserbar {
        background: #fff;
        color: #333;
        height: 45px;
        line-height: 45px;
        box-shadow: 0 0 4px #666;
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        align-items: center;
        font-size: 14px;
        margin-bottom: 10px;

        .uesrbox {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .userphoto {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;

            img {
                width: 30px;
            }
        }

        .name {
            font-size: 14px;
        }

        .rightlink {
            padding-right: 20px;
            background: url(../assets/down.svg) no-repeat center right;
            background-size: auto 8px;
        }
    }
</style>
