import request from "@/utils/request";


// 获取站点列表
export function getSites() {
  return request({
    url: '/site/list',
    method: 'get'
  })
}

// 获取地区列表
export function getAreaList () {
  return request({
    url: '/area/list',
    method: 'post'
  })
}
